import React from 'react';
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";

import './App.css';

import Home from "./components/Home";
import Dashboard from "./components/Dashboard";



function App(props) {


  return (
    
    <div className="App">
      <Router history={history}>
        <Switch>
            <Route path="/" exact component={Home}/>
            <Route path="/dashboard" exact component={Dashboard}/>
        </Switch>
      </Router>
    </div>
  )

}

export default App;
