import React, { useState, useEffect } from 'react'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider, withStyles, createTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
import PropTypes from "prop-types";

import LinearProgress from '@material-ui/core/LinearProgress';


const axios = require('axios').default;

const theme = createTheme({
    palette: {
      primary: green,
    },
  });

  const styles = {
    root: {
      margin:"1rem",
      background: "#fff",
      '& label.Mui-focused': {
        color: '#2a2a2a',
      },
    },
    input: {
      color: "black"
    },
    label: {
      color: "#2a2a2a"
    },
  };

const ColorButton = withStyles((theme) => ({
    root: {
        margin: "0rem 1rem",
        color: theme.palette.getContrastText("#2da64d"),
        backgroundColor: "#2da64d",
        '&:hover': {
        backgroundColor: "#2da64d",
        },
    },
}))(Button);


const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#d9dde5',
  },
  barColorPrimary: {
    backgroundColor: '#2da64d',
  },
})(LinearProgress);



function SearchBar(props) {

    const {classes} = props;
    const [completed, setCompleted] = useState(10);
    const [apiInterval, setApiInterval] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");


    const logSearch = async (householdId) => {
      await axios.post(
          "https://nfgnegzvae.execute-api.us-east-1.amazonaws.com/dev/interaction"+
          "?id="+householdId+
          "&mail="+props.userMail, {
           crossdomain: true
       })
       .then(res => {
           console.log("Logged")
       })
       .catch(err => {
          console.log('Unexpected Error Logging')});
    }


    const uploadPacientId = async (householdId) => {
        console.log("AXIOS")
        await axios.get(
            "https://nfgnegzvae.execute-api.us-east-1.amazonaws.com/dev/household"+
            "?id="+householdId, {
             crossdomain: true
         })
         .then(res => {
             console.log("Get Household Data")
             console.log(res)
             console.log(res["data"]["output"])
              if (res["data"]["output"]["status"]==="ok") {
                props.setHouseholdData(res["data"]["output"])
                props.setLoadingData(false)
                props.setHouseExists(true)}
              else if (res["data"]["output"]["status"]==="processing") {
                console.log('Request in Progress')
              }
              else if (res["data"]["output"]["status"]==="error"){
               console.log('Response with error on the household')
               props.setLoadingData(false)
               props.setHouseholdData(null)
               props.setHouseExists(false)
               setErrorMessage(res["data"]["output"]["error_message"])}
         })
         .catch(err => {
            console.log('Unexpected Error')
            console.log(err)
            props.setLoadingData(false)
            props.setHouseholdData(null)
            props.setHouseExists(false)
            setErrorMessage("Ocurrió un error inesperado")});
      }

    useEffect(() => {
        function progress() {
          setCompleted((oldCompleted) => {
            if (oldCompleted === 100) {
              return 100;
            }
            const diff = Math.floor(Math.random() * 4);
            return Math.min(oldCompleted + diff, 100);
          });
        }
    
        const timer = setInterval(progress, 500);
        return () => {
          clearInterval(timer);
        };
    }, [props.loadingData]);

    useEffect(() => {
      if (props.loadingData) {
        setApiInterval(setInterval( () => {
            uploadPacientId(props.householdId)
        }, 10000))
      } else {
        clearInterval(apiInterval)
      }
    },[props.loadingData])

    return (
        <div className="searchContainer">

            <ThemeProvider theme={theme}>
                <TextField 
                    className={classes.root}
                    InputProps={{
                        className: classes.input
                    }}
                    InputLabelProps={{
                        className: classes.label
                    }}
                    id="filled-basic" 
                    label="# FIS" 
                    variant="outlined"
                    value={props.householdId}
                    onChange={(event) => {
                        props.setHouseholdId(event.target.value) }} 
                    />

            </ThemeProvider>
                <ColorButton 
                    variant="contained"
                    onClick={() => {setCompleted(0)
                                    props.setLoadingData(true)
                                    props.setHouseholdData(null)
                                    logSearch(props.householdId)}}>
                        <Typography variant="button">
                            Analizar
                        </Typography>
            </ColorButton>

                
            {(props.loadingData) ? <div className="loadingContainer"> 
                                   {/*<div className="lds-facebook"><div></div><div></div><div></div></div>*/}
                                   <Typography className="loadingLabel"
                                                variant="subtitle1">
                                        Analizando hogar...
                                    </Typography>
                                   <ColorLinearProgress className="loadingAnimation" 
                                                        style={{width:"100%"}} 
                                                        variant="determinate" value={completed}/>
                                    
                                  </div>
                                :(props.houseExists) ? null 
                                : <Typography variant="h6" color="error">
                                      {errorMessage}
                                  </Typography>}

        </div>
    )
}


SearchBar.propTypes = {
    classes: PropTypes.object.isRequired
  };


export default withStyles(styles)(SearchBar);
