import React, { PureComponent } from 'react';
import {
  Radar, RadarChart, PolarGrid, Legend, Tooltip,
  PolarAngleAxis, PolarRadiusAxis,
} from 'recharts';


export default class Example extends PureComponent  {

  render() {
    return (
            <RadarChart cx={335} cy={210} outerRadius={165} data={this.props.householdData['radio_chart_values']} height={450} width={650}>
                <PolarGrid />
                {console.log(this.props.householdData)}
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis angle={0} domain={[0, 100]} />
                <Radar name="Hogares en pobreza (mediana)" dataKey="A" stroke="#2b2c44" strokeWidth={3} fill="transparent" />
                <Radar name="Hogar" dataKey="B" fill="#2da64d" fillOpacity={0.5} />
                <Legend />
                <Tooltip />
            </RadarChart>
    );
  }
}
