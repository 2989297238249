// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import AuthProvider from './components/AuthProvider';

import {domain} from './auth_config.json';
import {clientId} from './auth_config.json';


const options = {
  language: 'es',
  allowSignUp: true,
  //debug: true,
  //rememberLastLogin: true,
  languageDictionary: {
    title: "SCOPIA"
  },
  theme: {
    logo: 'https://prosperia-ds-platform.s3.amazonaws.com/1586387803829-Logo+-+tree+only.png',
    primaryColor: '#31324F',
  }
};

ReactDOM.render(
  <AuthProvider
    domain={domain}
    clientId={clientId}
    options = {options}
  >
    <App />
  </AuthProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
