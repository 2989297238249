import React from 'react';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';

const AuthProvider = ({ children, domain, clientId, redirectUri }) => {
  return (
      <Auth0Provider
          domain={domain}
          clientId={clientId}
          authorizationParams={{
            redirect_uri: redirectUri || window.location.origin,
          }}
      >
        {children}
      </Auth0Provider>
  );
};

AuthProvider.propTypes = {
  domain: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  redirectUri: PropTypes.string,
  children: PropTypes.node,
};

AuthProvider.defaultProps = {
  redirectUri: window.location.origin,
};

export default AuthProvider;
