import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography';
import {
    withStyles,
  } from '@material-ui/core/styles';
import {Avatar, IconButton, Menu, MenuItem} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";

const styles = {
    root: {
      margin:"1rem",
      background: "#fff",
      '& label.Mui-focused': {
        color: '#2a2a2a',
      },
    },
    input: {
      color: "black"
    },
    label: {
      color: "#2a2a2a"
    },
  };

function DashHeader() {
    const { logout, user } = useAuth0();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="headerContainer">

            <img 
                src="https://prosperia-ds-platform.s3.amazonaws.com/1588607315647-sinirube-logo-transparent-white.png"
                width ="60%"
                maxwidth= "70%"
                height= "auto"
                alt="SINIRUBE Logo" />
            <div></div>
            <div className="profileContainer">
                <IconButton onClick={handleMenuOpen}>
                    <Avatar alt={user?.name} src={user?.picture} />
                </IconButton>

                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem disabled>
                        <Typography variant="subtitle1">{user?.name}</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => logout({ returnTo: process.env.PUBLIC_URL})}>
                        Cerrar sesión
                    </MenuItem>
                </Menu>
            </div>
        </div>
    )
}

export default withStyles(styles)(DashHeader);
