import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Grid from '@material-ui/core/Grid';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Data from './Data';
import RadioChart from './RadioChart';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  expansionPanelHeader: {
    backgroundColor: "#2b2c44",
    color: "#fff"
  }
}));


export default function DataTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: "#2b2c44" }}>
        <Tabs 
            value={value} 
            onChange={handleChange} 
            aria-label="simple tabs example" 
            TabIndicatorProps={{
              style: {
                backgroundColor: "#FFF"
              }
            }}>

          <Tab label="Resumen por Categorías" {...a11yProps(0)} />
          <Tab label="Perfil Completo" {...a11yProps(1)} />
          <Tab label="Variables Destacadas" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} style={{display: "grid", alignItems:"start", justifyItems:"center"}}>
          <RadioChart householdId={props.householdId}
                      householdData={props.householdData}/>
      </TabPanel>
      
      <TabPanel value={value} index={1}>

        <ExpansionPanel>
          <ExpansionPanelSummary className={classes.expansionPanelHeader} expandIcon={<ExpandMoreIcon style={{ color: "#fff" }}/>}>
              <Typography variant="subtitle" gutterBottom>
                  Hogar
              </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Grid>

                      {Object.keys(props.householdData['house_table']).map( 
                        tableKey =>
                        <ExpansionPanel>
                          <ExpansionPanelSummary className={classes.expansionPanelHeader} expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}>
                              <Typography variant="subtitle" gutterBottom>
                                  {tableKey}
                              </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Data householdId={props.householdId}
                                  householdDataTable={props.householdData['house_table'][tableKey]}
                                  setHouseholdData={props.setHouseholdData} />
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      )}

            </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary className={classes.expansionPanelHeader} expandIcon={<ExpandMoreIcon style={{ color: "#fff" }}/>}>
              <Typography variant="subtitle" gutterBottom>
                  Individuos
              </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid style={{width:"99%"}}>

                      {Object.keys(props.householdData['ind_table']).map( 
                        tableKey =>
                        <ExpansionPanel >
                          <ExpansionPanelSummary className={classes.expansionPanelHeader} expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}>
                              <Typography variant="subtitle" gutterBottom>
                                  {tableKey}
                              </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Data householdId={props.householdId}
                                  householdDataTable={props.householdData['ind_table'][tableKey]}
                                  setHouseholdData={props.setHouseholdData} />
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      )}
                      
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        
      </TabPanel>

      <TabPanel value={value} index={2}>

        {Object.keys(props.householdData['top_tables']).map( 
                      tableKey =>
                      
                      <ExpansionPanel>
                        <ExpansionPanelSummary className={classes.expansionPanelHeader} expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}>
                            <Typography variant="subtitle" gutterBottom>
                                {tableKey}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Data householdId={props.householdId}
                            householdDataTable={props.householdData['top_tables'][tableKey]}
                            setHouseholdData={props.setHouseholdData} />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>

                    )}

      </TabPanel>

    </div>
  );
}
