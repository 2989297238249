import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, Tooltip, Label, ReferenceLine,
} from 'recharts';

import Typography from '@material-ui/core/Typography';

const data = [
  { name: "PE1", ingreso: 13666},
  { name: "PE2", ingreso: 11507},
  { name: "PE3", ingreso: 20690},
  { name: "PE4", ingreso: 26795},
  { name: "PE5", ingreso: 36275},
  { name: "P1", ingreso: 41881},
  { name: "P2", ingreso: 41944},
  { name: "P3", ingreso: 46625},
  { name: "P4", ingreso: 47727},
  { name: "P5", ingreso: 51257},
  { name: "V1", ingreso: 50494},
  { name: "V2", ingreso: 47485},
  { name: "V3", ingreso: 48549},
  { name: "V4", ingreso: 48311},
  { name: "NP1", ingreso: 43503},
  { name: "NP2", ingreso: 41595},
  { name: "NP3", ingreso: 41628},
  { name: "NP4", ingreso: 40476},
  { name: "NP5", ingreso: 35018},
  { name: "NP6", ingreso: 33281},
  { name: "NP7", ingreso: 30896},
  { name: "NP8", ingreso: 27354},
  { name: "NP9", ingreso: 32561},
  { name: "NP10", ingreso: 32665},
  { name: "NP11", ingreso: 26865},
  { name: "NP12", ingreso: 27158},
  { name: "NP13", ingreso: 23860},
  { name: "NP14", ingreso: 21335},
  { name: "NP15", ingreso: 24088},
  { name: "NP16", ingreso: 17054},
  { name: "NP17", ingreso: 22209},
  { name: "NP18", ingreso: 14703},
  { name: "NP19", ingreso: 16631},
  { name: "NP20", ingreso: 15456},
  { name: "NP21", ingreso: 16201},
  { name: "NP22", ingreso: 13419},
  { name: "NP23", ingreso: 13713},
  { name: "NP24", ingreso: 14423},
  { name: "NP25", ingreso: 17328},
  { name: "NP26", ingreso: 11194},
  /*{ name: "41", ingreso: 14387},
  { name: "41", ingreso: 10363},
  { name: "42", ingreso: 13342},
  { name: "43", ingreso: 12401},
  { name: "44", ingreso: 9119},
  { name: "45", ingreso: 8060},
  { name: "46", ingreso: 10208},
  { name: "47", ingreso: 10799},
  { name: "48", ingreso: 11064},
  { name: "49", ingreso: 7894},
  { name: "50", ingreso: 6876},
  { name: "51", ingreso: 7731},
  { name: "52", ingreso: 9944},
  { name: "53", ingreso: 6579},
  { name: "54", ingreso: 4729},
  { name: "55", ingreso: 5765},
  { name: "56", ingreso: 7612},
  { name: "57", ingreso: 8462},
  { name: "58", ingreso: 5543},
  { name: "59", ingreso: 4388},
  { name: "60", ingreso: 5083},
  { name: "61", ingreso: 4951},
  { name: "62", ingreso: 6646},
  { name: "63", ingreso: 6016},
  { name: "64", ingreso: 4406},
  { name: "65", ingreso: 5019},
  { name: "66", ingreso: 3860},
  { name: "67", ingreso: 6559},
  { name: "68", ingreso: 5150},
  { name: "69", ingreso: 3297},
  { name: "70", ingreso: 4561},
  { name: "71", ingreso: 2864},
  { name: "72", ingreso: 5070},
  { name: "73", ingreso: 2635},
  { name: "74", ingreso: 2721},
  { name: "75", ingreso: 5262},
  { name: "76", ingreso: 3310},
  { name: "77", ingreso: 3613},
  { name: "78", ingreso: 5339},
  { name: "79", ingreso: 151349}*/
];


  export default function Example(props){

  const [incomeLevel, setincomeLevel] = useState(0)
  const [verIncomeLevel, setVerIncomeLevel] = useState(0)


  useEffect(() => {
    if (props.householdData["income_bracket"]>=40) {
      setincomeLevel(39)
    } else {
    setincomeLevel(
      props.householdData["income_bracket"]-1
    )}

    if (props.householdData["verifiable_income_bracket"]>=40) {
      setVerIncomeLevel(39)
    } else {
    setVerIncomeLevel(
      props.householdData["verifiable_income_bracket"]-1
    )}

  },[props.householdData])

    return (

      (props.householdData!=null) ? (
        <div style={{width:"100%"}}>
          <Typography variant="h5">
            Grupo de Ingreso
          </Typography>
          <ResponsiveContainer width="99%" height={330} debounce={0}>
          <BarChart
            maxBarSize={15}
            data={data}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >

            <XAxis dataKey="name" tick={{fontSize: 0, fontFamily: "sans-serif"}} />
            <YAxis hide={true} type="number" domain={[0, 60000]} tickLine={true} tick={{fontSize: 16, fontFamily: "sans-serif"}} />
            <Tooltip  contentStyle={{fontSize: 16, fontFamily: "sans-serif"}} itemStyle={{color: "#fff"}}  />
            <ReferenceLine x="PE5" stroke="#d9dde5" ifOverflow="extendDomain">
                <Label value="PE" position="insideTopRight" style={{fontSize: 17, fontFamily: "sans-serif"}} />
            </ReferenceLine>
            <ReferenceLine x="P5" stroke="#d9dde5" ifOverflow="extendDomain">
                <Label value="P" position="insideTopRight" style={{fontSize: 17, fontFamily: "sans-serif"}}/>
            </ReferenceLine>
            <ReferenceLine x="V4" stroke="#d9dde5" ifOverflow="extendDomain">
                <Label value="V" position="insideTopRight" style={{fontSize: 17, fontFamily: "sans-serif"}}/>
            </ReferenceLine>
            <ReferenceLine x="NP26" stroke="#d9dde5" ifOverflow="extendDomain">
                <Label value="NP" position="insideTopRight" style={{fontSize: 17, fontFamily: "sans-serif"}}/>
            </ReferenceLine>
            {/* <Bar dataKey="ingreso" fill="#2da64d" />*/}
            <Bar dataKey="ingreso">
              {
                data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={index === incomeLevel ? "#2da64d" : index === verIncomeLevel ? "#aaa" : "#d9dde5"} />
                ))
              }
            </Bar>
            {/*<Legend iconType="line"/>*/}
          </BarChart>
          </ResponsiveContainer>
        </div>)
      :
      null
    );
  }
