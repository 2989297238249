import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import SearchBar from './SearchBar';
import Chart from './Chart';
import DataTabs from './DataTabs';
import {useAuth0} from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
    margin: "2rem 0rem 0rem 0rem"
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function kFormatter(num) {
    num = Math.round(num)
    return Math.abs(num) > 999 ? Math.sign(num)*(Math.round(Math.abs(num)/1000).toFixed(1)) + ' mil' : Math.sign(num)*Math.abs(num)
}

export default function ActiveDashboard() {
    const classes = useStyles();
    const [householdId, setHouseholdId] = useState("");
    const [householdData, setHouseholdData] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [houseExists, setHouseExists] = useState(true);
    const {user} = useAuth0();

  return (
    <div className={classes.root}>
      
        {(householdData!=null) ? (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                <Paper elevation={2}className={classes.paper}>
                        <SearchBar 
                            householdId={householdId}
                            setHouseholdId={setHouseholdId}
                            setHouseholdData={setHouseholdData}
                            loadingData={loadingData}
                            setLoadingData={setLoadingData}
                            houseExists={houseExists}
                            setHouseExists={setHouseExists}
                            userMail={user?.email}
                            />
                </Paper>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Grid item xs={12}>
                    <Paper elevation={2}className={classes.paper}>
                    
                        <div className="infoContainerInner">
                            <div>
                                <Typography align="left" variant="subtitle1"> 
                                <Box display="inline" fontWeight="fontWeightBold" m={1}> Actualización: </Box> {householdData["update_date"].split("T")[0].split("-")[2]}-{householdData["update_date"].split("T")[0].split("-")[1]}-{householdData["update_date"].split("T")[0].split("-")[0]} </Typography>
                            </div>
                            <div>
                                <Typography align="left" variant="subtitle1"> <Box display="inline" fontWeight="fontWeightBold" m={1}> Fuente: </Box> {householdData["source"]} </Typography>
                            </div>
                            <div>
                                <Typography align="left" variant="subtitle1"> <Box display="inline" fontWeight="fontWeightBold" m={1}> Tipo: </Box> {householdData["urban_rural"]} </Typography>
                            </div>
                            <div>
                                <Typography align="left" variant="subtitle1"> <Box display="inline" fontWeight="fontWeightBold" m={1}> Num. Personas: </Box> {householdData["household_size"]} </Typography>
                            </div>
                            <div>
                                <Typography align="left" variant="subtitle1"> <Box display="inline" fontWeight="fontWeightBold" m={1}> Ingreso Verificable: </Box> {householdData["verifiable_income_bracket_group"]} ₡{kFormatter(householdData["verifiable_income"])} </Typography>
                            </div>
                            <div>
                                <Typography align="left" variant="subtitle1"> <Box display="inline" fontWeight="fontWeightBold" m={1}> Clasificación: </Box>  <Box display="inline" color="#2da64d"> {householdData["income_bracket_group"]} </Box> </Typography>
                            </div>
                        </div>

                    </Paper>
                    </Grid>
                    <Grid item xs={12}>
                    <Paper elevation={2}className={classes.paper}>

                        <Chart householdData={householdData}/>

                    </Paper>
                    </Grid>
                    <Grid item xs={12}>
                    <Paper elevation={2}className={classes.paper}>
                        
                        <div>
                            <Typography variant="h5"> Variables Faltantes </Typography>
                            { (householdData["missing"].length===0) ?
                                <Typography variant="subtitle1"> No hay variables faltantes </Typography>
                                :
                                householdData["missing"].map((row, index) => (
                                    <Typography variant="subtitle1"> {row} </Typography>
                            ))}
                        </div>

                    </Paper>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={7}>
                    <Grid item xs={12}>
                    <Paper elevation={2}className={classes.paper}>

                        <DataTabs 
                            householdId={householdId}
                            householdData={householdData}
                            setHouseholdData={setHouseholdData}/>

                    </Paper>
                    </Grid>
                </Grid>
            </Grid>

        ): (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                <Paper elevation={2}className={classes.paper}>
                        <SearchBar 
                            householdId={householdId}
                            setHouseholdId={setHouseholdId}
                            setHouseholdData={setHouseholdData}
                            loadingData={loadingData}
                            setLoadingData={setLoadingData}
                            houseExists={houseExists}
                            setHouseExists={setHouseExists}
                            userMail={user?.email}
                            />
                </Paper>
                </Grid>
            </Grid>
        ) }
      
    </div>
  );
}
