import React, { useEffect } from 'react'
import { Redirect } from "react-router-dom";


import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import './Home.css'
import {useAuth0} from "@auth0/auth0-react";

const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText("#2da64d"),
      backgroundColor: "#2b2c44ff",
      '&:hover': {
        backgroundColor: "#2da64d",
      },
    },
  }))(Button);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

function AuthListener({isAuthenticated, history}){
    useEffect(() => {
        if (isAuthenticated) {
            history.push("/dashboard")
        }
    }, [isAuthenticated])
    return <></>
}

export default function Home(props) {

    const classes = useStyles();
    const { isAuthenticated, loginWithRedirect} = useAuth0();

    return (
            <div>
                <AuthListener isAuthenticated={isAuthenticated}
                                history={props.history} />
                {isAuthenticated ?
                (<Redirect to={"/dashboard"}/>)
                :
                (
                <div>
                    <div className="home">
                        <Grid container spacing={4}
                                direction="column"
                                justifyContent="center"
                                alignItems="center">
                            <Grid item xs={12}>
                                <img 
                                    src="https://prosperia-ds-platform.s3.amazonaws.com/1586387803829-Logo+-+tree+only.png"
                                    width ="25%"
                                    maxwidth= "30%"
                                    height= "auto"
                                    alt="Prosperia Logo" />
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Typography variant="h2">
                                <p style={{margin:"0px"}}>
                                    <span style={{color: "black"}}>SCOP</span>
                                    <span style={{color: "#2da64d"}}>IA</span>
                                </p>
                                </Typography>

                                <Typography variant="h5">
                                    Plataforma de Análisis de Casos Particulares
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <>
                                    <ColorButton
                                        className={classes.margin}
                                        variant="contained"
                                        //color="#2da64d"
                                        onClick={() => loginWithRedirect()}>
                                        <Typography variant="h6">
                                            Entrar
                                        </Typography>
                                    </ColorButton>
                                    {/*<Login/>*/}
                                </>

                            </Grid>
                        </Grid> 
                    </div>
                    <div className="footer"></div>
                </div>
                )
                }
            </div>
    )
}
