import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";

import DashHeader from './DashHeader';
import ActiveDashboard from './ActiveDashboard';

import './Dashboard.css';

// Material
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';

import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const styles = {
    root: {
        margin: "1rem",
        background: "#fff",
        '& label.Mui-focused': {
            color: '#2a2a2a',
        },
    },
    input: {
        color: "black",
    },
    label: {
        color: "#2a2a2a",
    },
};

function Dashboard(props) {

  const [userRole, setUserRole] = useState("");
  const [userMail, setUserMail] = useState("");
  const {isAuthenticated, user, profile} = useAuth0();

  const getUserRole = async (mail) => {
    await axios.get(
        "https://nfgnegzvae.execute-api.us-east-1.amazonaws.com/dev/user"+
        "?mail="+mail, {
         crossdomain: true
     })
     .then(res => {
          if (res["data"]["output"]["role"]==="active") {
            setUserRole("activeUser");
          }
          else {
            setUserRole("inactiveUser")
          }

     })
     .catch(err => {
        console.log('Unexpected Error')
        console.log(err)});
  }

  useEffect(() => {
    if (user?.email) {
      getUserRole(user?.email)
    }
  },[user?.email])

return (
        <div>
        <DashHeader />
          {(profile!==undefined) ? setUserMail(profile['email']) : null}
          {isAuthenticated ? (
              userRole==="activeUser" ?
                  
                <ActiveDashboard 
                      userMail={userMail}/>
                  
              : userRole==="inactiveUser" ?
              <div className="mainContainer"> 
                    <Paper className="inactiveContainer" elevation={0}>
                      <Typography variant="h5" color="error"> Contacte al administrador para activar su cuenta de usuario </Typography>
                    </Paper>
                </div>
              :
                <div className="mainContainer">
                  <div className="lds-facebook"><div></div><div></div><div></div></div>
                </div>

          ):
          (<Redirect to={"/"}/>)
          }
      </div>
)
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
