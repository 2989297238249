import React from 'react';

//Material
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';

import {
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2da64d",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 18,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    //marginTop: 20, 
    //marginRight: 20, marginLeft: 20, marginBottom: 20,
    //minWidth: 20,
  },
});

export default function Data(props) {

  const classes = useStyles();


  const getKeys = (dataTables) => {
    let keys = []
    for (let i=0; i<dataTables.length; i++){
      let table_keys = Object.keys(dataTables[i])
      for (let j=0; j<table_keys.length; j++){
        let key = table_keys[j]
        if (!keys.includes(key)){
          keys.push(key)
        }
      }
    }

    return keys.sort()
  }

    return (

        <TableContainer>
          
          {(props.householdDataTable!=null) ? 
            
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      {getKeys(props.householdDataTable).map( 
                        fieldName =>
                        <StyledTableCell>{fieldName}</StyledTableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.householdDataTable.map((row, index) => (
                      index<500 ?
                        <TableRow key={index}>
                          {getKeys(props.householdDataTable).map( 
                            (fieldValue, index2) =>
                            <StyledTableCell>
                              {index2===0 ? <Box> {row[fieldValue]} </Box> : row[fieldValue]}
                            </StyledTableCell>
                          )}
                        </TableRow>
                        : null
                    ))}
                  </TableBody>
                </Table>
            : null}
        </TableContainer> 
    )
}
